<template>
  <div class="box box_mm">
    <div class="box_main">
      <div class="search_box">
        <div class="search_item">
          <span style="width: 0.8rem">文件标题：</span>
          <el-input
            v-model="searchinput"
            placeholder="请输入"
            style="width: 2rem"
          ></el-input>
        </div>
        <div class="search_item">
          <el-button type="primary" @click="search">搜索</el-button>
          <!-- <el-button type="success" @click="addnew">新建公文处理单</el-button> -->
        </div>
      </div>
      <div class="box_cont">
        <div class="btn_list"></div>
        <div style="height: 76vh; box-sizing: border-box">
          <MyTable
            ref="zjlist"
            :tableData="tableData"
            :tableHeader="tableHeader"
            :showbm="true"
            :czwidth="150"
            @getdata="gettable"
            @changswitch="changswitch"
          >
            <template slot="btns" slot-scope="{ scope }">
              <el-button
                @click="showdetail(scope.row)"
                type="primary"
                size="mini"
                >详情</el-button
              >
              <el-button @click="daying(scope.row)" type="success" size="mini"
                >打印</el-button
              >
            </template>
          </MyTable>
        </div>
      </div>
    </div>

    <!--审核弹出-->
    <el-dialog
      title="确认拟办"
      :visible.sync="showtc"
      width="40%"
      :before-close="setsheng"
    >
      <div>
        <el-form ref="ruleForm" class="demo-ruleForm" label-position="left">
          <el-form-item label="拟办意见" required>
            <el-input
              type="textarea"
              :rows="4"
              placeholder="请输入拟办意见"
              v-model="sheng.nbyj"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="批示人" required label-width="100px">
            <div style="display: flex; align-items: center">
              <el-button type="primary" size="small" @click="select(1)"
                >选择</el-button
              >
            </div>
            <div>
              <span style="color: #999">已选人员：</span>{{ user_name }}
            </div>
          </el-form-item>
          <el-form-item label="领导批示人" required label-width="100px">
            <div style="display: flex; align-items: center">
              <el-button type="primary" size="small" @click="select(4)"
                >选择</el-button
              >
            </div>
            <div>
              <span style="color: #999">已选人员：</span>{{ user_name4 }}
            </div>
          </el-form-item>
          <el-form-item label="交办人" required label-width="100px">
            <div style="display: flex; align-items: center">
              <el-button type="primary" size="small" @click="select(3)"
                >选择</el-button
              >
            </div>
            <div>
              <span style="color: #999">已选人员：</span>{{ user_name3 }}
            </div>
          </el-form-item>
          <el-form-item label="抄送人" label-width="100px">
            <div style="display: flex; align-items: center">
              <el-button type="primary" size="small" @click="select(2)"
                >选择</el-button
              >
            </div>
            <div>
              <span style="color: #999">已选人员：</span>{{ user_name2 }}
            </div>
          </el-form-item>
        </el-form>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="setsheng">取 消</el-button>
        <el-button type="success" @click="queren">通 过</el-button>
      </span>
    </el-dialog>

    <detail2 ref="mydetail"></detail2>
    <selectuser ref="myselectuser" @selectedry="selectedry"></selectuser>
  </div>
</template>

<script>
import detail2 from "../components/detail2.vue";
import selectuser from "@/components/select_user.vue";
export default {
  components: { detail2, selectuser },
  data() {
    return {
      searchinput: "",
      tableHeader: [
        {
          prop: "id",
          name: "序",
          width: "50",
        },
        {
          prop: "name",
          name: "文件标题",
          width: "",
        },
        {
          prop: "lwdw",
          name: "来文单位",
          width: "250",
        },
        {
          prop: "jjcd",
          name: "紧急程度",
          width: "120",
          type: "jjcd",
        },
        {
          prop: "type",
          name: "当前环节",
          width: "110",
          type: "huanjie",
        },
        {
          prop: "addtime",
          name: "创建时间",
          width: "",
        },
      ],
      tableData: [],
      showtc: false,
      sheng: {
        id: "",
        nbyj: "",
        psuser_id: "",
        jbuser_id: "",
        wpsuser_id: "",
        csr: "",
      },
      selet_type: 0,
      user_name: "",
      user_id: "",
      user_name2: "",
      user_id2: "",
      user_name3: "",
      user_id3: "",
      user_name4: "",
      user_id4: "",
    };
  },
  mounted() {
    this.gettable();
  },
  methods: {
    queren() {
      if (!this.sheng.nbyj) {
        this.$message.warning("请输入拟办意见");
        return;
      }
      if (!this.sheng.psuser_id) {
        this.$message.warning("请选择批示人");
        return;
      }
      this.$post({
        url: "/api/matter/niban",
        params: this.sheng,
      }).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.gettable();
        this.setsheng();
      });
    },
    setsheng() {
      this.sheng = {
        id: "",
        nbyj: "",
        psuser_id: "",
        csr: "",
      };
      this.showtc = false;
    },
    select(index) {
      this.selet_type = index;
      let cxidlist = [];
      let cxnamelist = [];
      if (index == 1) {
        //选择批示人
        cxidlist = this.user_id ? this.user_id.split(",") : [];
        cxnamelist = this.user_name ? this.user_name.split(",") : [];
        this.$refs.myselectuser.isdanxuan = false;
      }
      if (index == 2) {
        //选择抄送人
        this.$refs.myselectuser.isdanxuan = false;
        cxidlist = this.user_id2 ? this.user_id2.split(",") : [];
        cxnamelist = this.user_name2 ? this.user_name2.split(",") : [];
      }
      if (index == 3) {
        //选择交办人
        this.$refs.myselectuser.isdanxuan = false;
        cxidlist = this.user_id3 ? this.user_id3.split(",") : [];
        cxnamelist = this.user_name3 ? this.user_name3.split(",") : [];
      }
      if (index == 4) {
        //领导批示人
        this.$refs.myselectuser.isdanxuan = false;
        cxidlist = this.user_id4 ? this.user_id4.split(",") : [];
        cxnamelist = this.user_name4 ? this.user_name4.split(",") : [];
      }
      let list = [];
      if (cxidlist.length > 0) {
        cxidlist.forEach((item, index) => {
          let obj = {
            name: cxnamelist[index],
            id: Number(item),
          };
          list.push(obj);
        });
      }
      this.$refs.myselectuser.selectuser = list.length > 0 ? list : [];
      this.$refs.myselectuser.dialogVisible = true;
    },
    selectedry(e) {
      if (this.selet_type == 1) {
        this.user_id = e.map((item) => {
          return item.id;
        });
        this.user_id = this.user_id.join(",");
        this.user_name = e.map((item) => {
          return item.name;
        });
        this.user_name = this.user_name.join(",");

        this.sheng.psuser_id = this.user_id;
      }
      if (this.selet_type == 2) {
        this.user_id2 = e.map((item) => {
          return item.id;
        });
        this.user_id2 = this.user_id2.join(",");
        this.user_name2 = e.map((item) => {
          return item.name;
        });
        this.user_name2 = this.user_name2.join(",");

        this.sheng.csr = this.user_id2;
      }
      if (this.selet_type == 3) {
        this.user_id3 = e.map((item) => {
          return item.id;
        });
        this.user_id3 = this.user_id3.join(",");
        this.user_name3 = e.map((item) => {
          return item.name;
        });
        this.user_name3 = this.user_name3.join(",");

        this.sheng.jbuser_id = this.user_id3;
      }
      if (this.selet_type == 4) {
        this.user_id4 = e.map((item) => {
          return item.id;
        });
        this.user_id4 = this.user_id4.join(",");
        this.user_name4 = e.map((item) => {
          return item.name;
        });
        this.user_name4 = this.user_name4.join(",");

        this.sheng.wpsuser_id = this.user_id4;
      }
    },
    daying(row) {
      this.$post({
        url: "/api/matter/details",
        params: {
          id: row.id,
        },
      }).then((res) => {
        this.$refs.mydetail.info = res;
        // this.URLToBase64("nb", this.$refs.mydetail.info.nb.url, this.toBase64);
        // this.URLToBase64("ps", this.$refs.mydetail.info.ps.url, this.toBase64);
        // this.URLToBase64("yj", this.$refs.mydetail.info.yj.url, this.toBase64);

        this.$refs.mydetail.dialogVisible = true;
        setTimeout(() => {
          this.$refs.mydetail.printBtn();
        }, 500);
      });
    },
    showdetail(row) {
      this.$post({
        url: "/api/matter/details",
        params: {
          id: row.id,
        },
      }).then((res) => {
        this.$refs.mydetail.info = res;
		// this.URLToBase64("nb", this.$refs.mydetail.info.nb.url, this.toBase64);
        // this.URLToBase64("ps", this.$refs.mydetail.info.ps.url, this.toBase64);
        // this.URLToBase64("yj", this.$refs.mydetail.info.yj.url, this.toBase64);
        this.$refs.mydetail.showprint = true;
        this.$refs.mydetail.dialogVisible = true;
      });
    },
    URLToBase64(name, url, callback) {
      let image = new Image();
      image.setAttribute("crossOrigin", "Anonymous");
      image.src = url;
      image.onload = function () {
        let canvas = document.createElement("canvas");
        canvas.width = image.naturalWidth;
        canvas.height = image.naturalHeight;
        canvas?.getContext("2d")?.drawImage(image, 0, 0);
        let result = canvas.toDataURL("image/png");
        callback(name, result);
      };
    },
    toBase64(name, e) {
      if (name == "nb") {
        this.$refs.mydetail.info.nb.url = e;
        return;
      }
      if (name == "ps") {
        this.$refs.mydetail.info.ps.url = e;
        return;
      }
      if (name == "yj") {
        this.$refs.mydetail.info.yj.url = e;
        return;
      }
      if (name == "mgrzp_url") {
        this.info.xcxescm.grzp_url = e;
        return;
      }
      this.info[name] = e;
    },
    postdata(row) {
      this.showtc = true;
      this.sheng.nbyj = row.nbyj;
      this.sheng.id = row.id;
    },
    showeidt(row) {
      this.$router.push("/system/get_file/edit?id=" + row.id);
    },
    changswitch(row) {
      this.$post({
        url: "/api/room/edit",
        params: row,
      }).then((res) => {
        this.$message({
          type: "success",
          message: "操作成功!",
        });
        this.gettable();
      });
    },
    search() {
      this.$refs.zjlist.inputpage = 1;
      this.gettable();
    },
    gettable() {
      this.$post({
        url: "/api/matter/ybl",
        params: {
          name: this.searchinput,
          p: this.$refs.zjlist.inputpage,
          size: this.$refs.zjlist.pagesize,
        },
      }).then((res) => {
        this.tableData = res.list;
        this.$refs.zjlist.total = res.count;
      });
    },
    addnew() {
      this.$router.push("/system/get_file/add");
    },
    showdate(row) {
      this.$refs.myroomdate.dialogVisible = true;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../public.scss";
</style>
